import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { retry } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { AlertService } from "./alert.service";
import { ListActionsComponentService } from "./list-actions-component.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public token: string = "Test Token";
  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private listActionsComponentService: ListActionsComponentService
  ) {}

  // Following Is Common Event For api
  uploadImage(url: string, formData: FormData) {
    if (url) {
      return this.http.post(
        environment.apiCredentials.apiDomain + url.trim(),
        formData
      );
    }
  }

  backendAPICall(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({ "Content-Type": "application/json" });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
  AuthAPICall(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + "/" + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
  redirectToLogin(): void {
    this.alertService.create(
      "Session Expired",
      "error",
      20,
      "Your session expired, please login again."
    );
    localStorage.clear();
    this.router.navigate(["/login"]);
    
  }
}
