import { Component } from '@angular/core';

import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})
export class AppComponent {
  title = 'COWebsite';
  MenOffers$: any;
  user: any = {};
  posts: any;
  loading = false;
  constructor( private router: Router) {
    this.user = {
      username: 'RITU',
      password: '@ritu123',
    };
  }

}
