<app-alert></app-alert>
<div id="login-panel-container">
    <div class="row">
      <div class="logo-panel border col-md-5">
        <div class="logo-block">
          <img src="../../../assets/images/logo.png" />
        </div>
      </div>
      <div class="login-filed-panel col-md-7">
        <h3>
          Admin Login
          <span>Enter email and Password to log on</span>
        </h3>
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        <div class="form-content-panel">
          <form
            name="form"
            (ngSubmit)="lf.form.valid && login()"
            #lf="ngForm"
            novalidate
          >
            <div
              class="form-filed"
              [ngClass]="{ 'has-error': lf.submitted && !email }"
            >
              <span class="fas fa-user-circle"></span>
              <input
                type="text"
                placeholder="email"
                name="email"
                class="form-control"
                [(ngModel)]="user.email"
                #email
                required
              />
              <div *ngIf="lf.submitted && !email" class="help-block">
                email is required
              </div>
            </div>
            <div
              class="form-filed"
              [ngClass]="{ 'has-error': lf.submitted && !password }"
            >
              <span class="fas fa-lock"></span>
              <input
                autocomplete="off"
                type="password"
                placeholder="Password"
                name="password"
                class="form-control"
                [(ngModel)]="user.password"
                #password
                required
              />
              <div *ngIf="lf.submitted && !password" class="help-block">
                Password is required
              </div>
            </div>
            <div class="row mt-2">
              <div class="col d-flex justify-content-center">
                <!-- Checkbox -->
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="form2Example31"
                    checked
                  />
                  <label class="form-check-label" for="form2Example31">
                    Remember me
                  </label>
                </div>
              </div>
  
              <!-- <div class="col">
                <a href="/forgot-password" class="text-decoration-none" style="color: #C03613 !important;">Forgot password?</a>
              </div> -->
            </div>
            <div class="form-filed-btn">
              <button class="btn btn-primary" [disabled]="loading">
                <span class="fas fa-arrow-right"></span>
                Login
              </button>
              <img
                *ngIf="loading"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
              <!-- Text to be added below the login button -->
              <!-- <div class="text-center mt-3">
                <p>
                  Not Registered Yet?
                  <a href="/register"  class="text-decoration-none" style="color: #C03613 !important;">Register here</a>
                </p>
              </div> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="loginbg"></div>
  