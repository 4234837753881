import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { MaterialModule } from "./shared/modules/material/material.module";
import { CommonService } from "./shared/services/common.service";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CandidateFormComponent } from "./candidate-form/candidate-form.component";
import { LoginComponent } from "./auth/login.component";
import { AlertService } from "./shared/services/alert.service";
import { AlertComponent } from "./shared/alert/alert.component";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  declarations: [AppComponent, CandidateFormComponent, LoginComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    ReactiveFormsModule,
    SharedModule,FormsModule
  ],
  exports: [],
  providers: [CommonService, AlertService],
  schemas: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
