import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CandidateFormComponent } from "./candidate-form/candidate-form.component";
import { LoginComponent } from "./auth/login.component";
import { NotFoundComponent } from "./components/not-found-page";

const routes: Routes = [
  {
    path: "candidate-form",
    component: CandidateFormComponent,
  },
  { path: '', redirectTo: '/candidate-form', pathMatch: 'full' },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
