<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Candidate Registration</title>
  </head>

  <body>
    <div class="d-flex text-center py-3 px-5 align-items-center">
      <a class="" href="/">
        <img
          class="img-fluid"
          style="width: 8rem"
          src="../../assets/images/logo.png"
          alt=""
        />
      </a>
      <div class="heading">Candidate Registration</div>

      <a *ngIf="!token" href="/login" class="btn btn-outline-info login-btn"
        >Admin Login</a
      >
      <b *ngIf="token" (click)="goBack()" class="btn login-btn btn-outline-info"
        >Back to Home</b
      >
    </div>
    <div *ngIf="loading" class="loading-container">
      <div class="spinner"></div>
      <p>Loading...</p>
    </div>
    <div class="container">
      <form
        [formGroup]="candidateForm"
        (ngSubmit)="onSubmit()"
        class="form pb-3"
        novalidate
      >
        <mat-accordion class="w-100" multi>
          <div class="row">
            <div class="col-md-6">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Document Details*
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <mat-label>Mobile </mat-label>
                    <input
                      matInput
                      placeholder="Mobile"
                      formControlName="mobile"
                      [readonly]="isMobileVerified"
                      required
                      maxlength="10"
                    />
                  </mat-form-field>
                  <b
                    class="text-danger"
                    *ngIf="
                      cf.mobile.invalid &&
                      (cf.mobile.dirty || cf.mobile.touched)
                    "
                  >
                    <b class="text-danger" *ngIf="cf.mobile.errors.required">
                      Mobile No. is required
                    </b>
                    <b class="text-danger" *ngIf="cf.mobile.errors.pattern">
                      Only integer value is allowed
                    </b>
                    <b
                      class="text-danger"
                      *ngIf="
                        cf.mobile.errors.minlength || cf.mobile.errors.maxlength
                      "
                    >
                      Only 10 Digit mobile no. allowed
                    </b>
                  </b>

                  <div class="float-right">
                    <b
                      class="btn btn-danger py-0"
                      *ngIf="!isMobileVerified"
                      (click)="sendOTP()"
                    >
                      Send Mobile Otp
                    </b>
                  </div>
                  <div class="form-field">
                    <div *ngIf="mobileOtpSent">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          placeholder="Enter otp "
                          maxlength="4"
                          *ngIf="mobileOtpSent"
                          type="number"
                          formControlName="otp"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="float-right">
                    <b
                      class="btn btn-info py-0"
                      *ngIf="mobileOtpSent"
                      (click)="verifyOTP()"
                    >
                      Verify Mobile
                    </b>
                  </div>
                  <div class="float-right">
                    <div *ngIf="mobileVerMsg" class="verification-message">
                      <mat-icon style="color: green">check_circle</mat-icon
                      >{{ mobileVerMsg }}
                    </div>
                  </div>
                </div>
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Email"
                      formControlName="email"
                      [readonly]="isMailVerified"
                      required
                    />
                  </mat-form-field>
                  <b
                    class="text-danger"
                    *ngIf="
                      cf.email.invalid && (cf.email.dirty || cf.email.touched)
                    "
                  >
                    <div *ngIf="cf.email.errors.required">
                      Email is required
                    </div>
                    <div *ngIf="cf.email.errors.email">
                      Enter valid email address
                    </div>
                  </b>

                  <div class="float-right">
                    <b
                      class="btn btn-danger py-0"
                      *ngIf="!isMailVerified"
                      (click)="sendMailOTP()"
                    >
                      Send Email OTP
                    </b>
                  </div>
                  <div class="form-field">
                    <div *ngIf="mailOtpSent">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          placeholder="Enter otp"
                          maxlength="4"
                          *ngIf="mailOtpSent"
                          type="number"
                          formControlName="mailOtp"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="float-right">
                    <b
                      class="btn btn-info py-0"
                      *ngIf="mailOtpSent"
                      (click)="verifyMailOTP()"
                    >
                      Verify Mail
                    </b>
                  </div>
                  <div class="float-right">
                    <div *ngIf="mailVerMsg" class="verification-message">
                      <mat-icon style="color: green">check_circle</mat-icon
                      >{{ mailVerMsg }}
                    </div>
                  </div>
                </div>

                <!-- pan input -->

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="PAN No."
                      formControlName="pan_number"
                      [readonly]="isPanVerified"
                      [required]="!hasNoPan"
                    />
                    <b
                      class="text-danger"
                      *ngIf="
                        cf.pan_number.invalid &&
                        (cf.pan_number.dirty || cf.pan_number.touched)
                      "
                    >
                      <div *ngIf="cf.pan_number.errors.required">
                        pan number is required
                      </div>
                      <div *ngIf="cf.pan_number.errors.pan_number">
                        Enter valid pan number address
                      </div>
                    </b>
                  </mat-form-field>
                </div>
                <div class="float-left">
                  <div class="col d-flex justify-content-center">
                    <!-- Checkbox -->
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="hasNoPan"
                        (change)="toggleNoPanCheckbox($event)"
                      />
                      <label class="form-check-label"> I have No Pan </label>
                    </div>
                  </div>
                </div>
                <div class="float-right">
                  <b
                    class="btn btn-info py-0"
                    *ngIf="!isPanVerified"
                    (click)="verifyPan()"
                  >
                    Verify PAN
                  </b>
                  <div *ngIf="panVerMsg" class="verification-message">
                    <mat-icon style="color: green">check_circle</mat-icon
                    >{{ panVerMsg }}
                  </div>
                </div>

                <!-- adhar input -->
                <!-- <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Aadhar No."
                      type="number"
                      formControlName="aadhar_number"
                      [readonly]="isAadharVerified"
                      maxlength="12"
                      required
                    />
                    <b
                      class="text-danger"
                      *ngIf="
                        cf.aadhar_number.invalid &&
                        (cf.aadhar_number.dirty || cf.aadhar_number.touched)
                      "
                    >
                      <div *ngIf="cf.aadhar_number.errors.required">
                        Aadhar No. is required
                      </div>
                      <div *ngIf="cf.aadhar_number.errors.aadhar_number">
                        Enter valid Aadhar No. address
                      </div>
                    </b>
                  </mat-form-field>
                </div>
                <div class="float-right">
                  <b
                    class="btn btn-danger py-0"
                    *ngIf="!isAadharVerified"
                    (click)="sendAadharOtp()"
                  >
                    Send Otp
                  </b>
                </div>
                <div class="form-field">
                  <div *ngIf="aadharOtpSent">
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Enter otp "
                        *ngIf="aadharOtpSent"
                        type="number"
                        formControlName="aadharOtp"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="float-right">
                  <b
                    class="btn btn-info py-0"
                    *ngIf="aadharOtpSent"
                    (click)="aadharVerify()"
                  >
                    Verify Aadhar
                  </b>
                </div>
                <div class="float-right">
                  <div *ngIf="aadharVerMsg" class="verification-message">
                    <mat-icon style="color: green">check_circle</mat-icon
                    >{{ aadharVerMsg }}
                  </div>
                </div> -->
                <!-- account number input -->

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Account No."
                      type="number"
                      formControlName="bank_ac_number"
                      [readonly]="isBankVerified"
                      [required]="!hasNoBank"
                    />
                    <b
                      class="text-danger"
                      *ngIf="
                        cf.bank_ac_number.invalid &&
                        (cf.bank_ac_number.dirty || cf.bank_ac_number.touched)
                      "
                    >
                      <div *ngIf="cf.bank_ac_number.errors.required">
                        Bank Account No. is required
                      </div>
                    </b>
                  </mat-form-field>
                </div>
                <!-- ifsc input -->

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="IFSC No."
                      formControlName="bank_ifsc"
                      [readonly]="isBankVerified"
                      maxlength="11"
                      [required]="!hasNoBank"
                    />

                    <b
                      class="text-danger"
                      *ngIf="
                        cf.bank_ifsc.invalid &&
                        (cf.bank_ifsc.dirty || cf.bank_ifsc.touched)
                      "
                    >
                      <div *ngIf="cf.bank_ifsc.errors.required">
                        IFSC No. is required
                      </div>
                      <div *ngIf="cf.bank_ifsc.errors.bank_ifsc">
                        Enter valid IFSC No. address
                      </div>
                    </b>
                  </mat-form-field>
                </div>

                <div class="float-left">
                  <div class="col d-flex justify-content-center">
                    <!-- Checkbox -->
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="hasNoBank"
                        (change)="toggleNoBankCheckbox($event)"
                      />
                      <label class="form-check-label" for="form2Example31">
                        I have No Bank Account
                      </label>
                    </div>
                  </div>
                </div>
                <div class="float-right">
                  <b
                    class="btn btn-info py-0"
                    *ngIf="!isBankVerified"
                    (click)="verifyBankAccount()"
                  >
                    Verify Bank Account
                  </b>
                  <div *ngIf="BankVerMsg" class="verification-message">
                    <mat-icon style="color: green">check_circle</mat-icon
                    >{{ BankVerMsg }}
                  </div>
                </div>

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Account Name"
                      formControlName="bank_ac_name"
                      [required]="!hasNoBank"
                    />
                    <b
                      class="text-danger"
                      *ngIf="
                        cf.bank_ac_name.invalid &&
                        (cf.bank_ac_name.dirty || cf.bank_ac_name.touched)
                      "
                    >
                      <div *ngIf="cf.bank_ac_name.errors.required">
                        Bank Account Name is required
                      </div>
                    </b>
                  </mat-form-field>
                </div>
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Name"
                      formControlName="bank_name"
                      [required]="!hasNoBank"
                    />
                    <b
                      class="text-danger"
                      *ngIf="
                        cf.bank_name.invalid &&
                        (cf.bank_name.dirty || cf.bank_name.touched)
                      "
                    >
                      <div *ngIf="cf.bank_name.errors.required">
                        bank name is required
                      </div>
                    </b>
                  </mat-form-field>
                </div>
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Branch Name"
                      formControlName="bank_branch"
                    />
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-md-6">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Document Uploads*
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div
                    *ngFor="let field of imageFields"
                    class="form-field col-md-6"
                  >
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="candidateForm.controls[field].value"
                          [src]="candidateForm.controls[field].value"
                          width="80"
                          height="60"
                        />
                        <div
                          *ngIf="imgLoading[field]"
                          class="image-loader"
                        ></div>
                        <svg
                          *ngIf="!candidateForm.controls[field].value"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">{{
                          replaceUnderscores(field)
                        }}</span>
                      </div>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectImage($event, field)"
                      />
                    </label>
                    <b
                      *ngIf="candidateForm.controls[field].value"
                      title="remove image"
                      class="image-remove-btn"
                      (click)="removeImage(field)"
                      >X</b
                    >
                  </div>
                  <!-- <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="profilePhoto"
                          width="80"
                          height="60"
                          [src]="profilePhoto"
                        />
                        <svg
                          *ngIf="!profilePhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">Profile Photo </span>
                      </div>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectProfilePhoto($event)"
                      />
                    </label>
                  </div>
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="panPhoto"
                          width="80"
                          height="60"
                          [src]="panPhoto"
                          alt="PAN"
                        />
                        <svg
                          *ngIf="!panPhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">PAN Photo</span>
                      </div>
                      <input
                        type="file"
                        id="panAttachment"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectPanPhoto($event)"
                      />
                    </label>
                  </div>
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="aadharPhoto"
                          width="80"
                          height="60"
                          [src]="aadharPhoto"
                          alt="Aadhar"
                        />
                        <svg
                          *ngIf="!aadharPhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">Aadhar Photo</span>
                      </div>
                      <input
                        type="file"
                        id="aadharAttachment"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectAadharPhoto($event)"
                      />
                    </label>
                  </div>
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="bankChequePhoto"
                          width="80"
                          height="60"
                          [src]="bankChequePhoto"
                        />
                        <svg
                          *ngIf="!bankChequePhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">Bank Cheque Photo </span>
                      </div>
                      <input
                        type="file"
                        id="bankAttachment"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectBankChequePhoto($event)"
                      />
                    </label>
                  </div>
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="QuliDocPhoto"
                          width="80"
                          height="60"
                          [src]="QuliDocPhoto"
                        />
                        <svg
                          *ngIf="!QuliDocPhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button"
                          >Qualification Document
                        </span>
                      </div>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectQuliDocPhoto($event)"
                      />
                    </label>
                  </div>
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="EmpDocPhoto"
                          width="80"
                          height="60"
                          [src]="EmpDocPhoto"
                        />
                        <svg
                          *ngIf="!EmpDocPhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button"
                          >Employment Document
                        </span>
                      </div>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectEmpDocPhoto($event)"
                      />
                    </label>
                  </div>
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="SignPhoto"
                          width="80"
                          height="60"
                          [src]="SignPhoto"
                        />
                        <svg
                          *ngIf="!SignPhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">Signature </span>
                      </div>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectSignPhoto($event)"
                      />
                    </label>
                  </div> -->
                </div>
              </mat-expansion-panel>
            </div>
            <!-- <div class="col-12">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Personnel Details*
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form-field row mt-2">
                  <h4 class="col-md-3 ml-1 float-left">Gender *</h4>
                </div>
                <mat-form-field class="full-width w-100">
                  <input
                    placeholder="Father/Husband Name"
                    matInput
                    formControlName="fatherHusbandName"
                    required
                  />
                  <b class="text-danger"
                    *ngIf="
                      candidateForm
                        .get('fatherHusbandName')
                        .hasError('required')
                    "
                    >Father/Husband Name is required</b
                  >
                </mat-form-field>
                <div class="form-field row mt-2">
                  <h4 class="col-md-3 ml-1 float-left">Relation *</h4>
                  <mat-radio-group
                    formControlName="fatherHusbandRelation"
                    aria-labelledby="radio-group-label"
                    class="ml-2"
                  >
                    <mat-radio-button value="F" class="mr10"
                      >Father
                    </mat-radio-button>
                    <mat-radio-button value="H">Husband</mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <textarea
                      matInput
                      placeholder="Address"
                      formControlName="addressLine1"
                      required
                      rows="3"
                    ></textarea>
                    <b class="text-danger"
                      *ngIf="
                        candidateForm.get('addressLine1').hasError('required')
                      "
                      >Address is required</b
                    >
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="form-field col-6">
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        inputmode="numeric"
                        placeholder="Pincode"
                        formControlName="pincode"
                        required
                      />
                      <b class="text-danger"
                        *ngIf="
                          candidateForm.get('pincode').hasError('required')
                        "
                        >required</b
                      >
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </div> -->
            <div class="col-12">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Background Verification Form*
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="page1" id="BGVForm">
                  <!-- <p class="my-2">
                    Please ensure that the details provided on this form are
                    correct and complete in all respects, as this information
                    will be used for background screening purposes and all other
                    official requirements.
                  </p> -->
                  <div class="row mt-4 py-2 ">
                    <b class="fs-5 text-bloder border-bottom">PERSONAL INFORMATION</b>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-3 py-2">*</div>
                    <div class="col-3 py-2">Middle Name</div>
                    <div class="col-3 py-2">Surname*</div>
                    <div class="col-3 py-2">Alias/Nickname</div>
                  </div> -->

                  <div class="row text-center">
                    <div class="form-field col-3">
                      <mat-form-field class="full-width w-100">
                        <mat-label>First Name </mat-label>
                        <input
                          matInput
                          placeholder="First Name"
                          
                          
                          
                          formControlName="first_name"
                          required
                        />
                      </mat-form-field>
                      <b
                        class="text-danger"
                        *ngIf="
                          cf.first_name.invalid &&
                          (cf.first_name.dirty || cf.first_name.touched)
                        "
                      >
                        <div *ngIf="cf.first_name.errors.required">
                          first name is required
                        </div>
                      </b>
                    </div>
                    <div class="form-field col-3">
                      <mat-form-field class="full-width">
                        <mat-label>Middle Name</mat-label>

                        <input matInput placeholder="Middle Name" formControlName="middle_name" />
                      </mat-form-field>
                    </div>
                    <div class="form-field col-3">
                      <mat-form-field class="full-width w-100">
                        <mat-label>Surname</mat-label>
                        <input matInput placeholder="Surname" formControlName="last_name" required />
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.last_name.invalid &&
                            (cf.last_name.dirty || cf.last_name.touched)
                          "
                        >
                          <div *ngIf="cf.last_name.errors.required">
                            last name is required
                          </div>
                        </b>
                    </div>
                    <div class="form-field col-3">
                      <mat-form-field class="full-width">
                        <mat-label>Alias/Nickname</mat-label>
                        <input placeholder="Alias/Nickname" matInput formControlName="nick_name" />

                        <!-- <b
                          class="text-danger"
                          *ngIf="
                            cf.nick_name.invalid &&
                            (cf.nick_name.dirty || cf.nick_name.touched)
                          "
                        >
                          <div *ngIf="cf.nick_name.errors.required">
                            nick name is required
                          </div>
                        </b> -->
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- <div class="row py-2">
                    <i class="text-white">For HR Department use only</i>
                  </div>

                  <div class="row text-center gap-3 text-bloder">
                    <div class="col-2 py-2">Date of Joining</div>
                    <div class="col-2 py-2">Employee Code</div>
                    <div class="col-2 py-2">Designation</div>
                    <div class="col-2 py-2">Department</div>
                    <div class="col-2 py-2">Location</div>
                  </div>
                  <div class="row text-center gap-3">
                    <div class="form-field col-2">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          [matDatepicker]="date_of_joining"
                          formControlName="date_of_joining"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="date_of_joining"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #date_of_joining
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="form-field col-2">
                      <mat-form-field class="full-width">
                        <input matInput formControlName="employee_code" />
                      </mat-form-field>
                    </div>
                    <div class="form-field col-2">
                      <mat-form-field class="full-width">
                        <input matInput formControlName="designation" />
                      </mat-form-field>
                    </div>
                    <div class="form-field col-2">
                      <mat-form-field class="full-width">
                        <input matInput formControlName="department" />
                      </mat-form-field>
                    </div>
                    <div class="form-field col-2">
                      <mat-form-field class="full-width">
                        <textarea
                          matInput
                          formControlName="department_location"
                        ></textarea>
                      </mat-form-field>
                    </div>
                  </div> -->

                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Date of Birth*</div>
                    <div class="col-6 py-2">Marital Status*</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Date of Birth</mat-label>
                        <input
                          matInput
                          placeholder="Date of Birth"
                          [matDatepicker]="date_of_birth"
                          formControlName="date_of_birth"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="date_of_birth"
                        ></mat-datepicker-toggle>
                        <mat-datepicker touchUi #date_of_birth></mat-datepicker>

                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.date_of_birth.invalid &&
                            (cf.date_of_birth.dirty || cf.date_of_birth.touched)
                          "
                        >
                          <div *ngIf="cf.date_of_birth.errors.required">
                            Date of birth is required
                          </div>
                        </b>
                    </div>
                    <div class="form-field col-6">
                      <mat-radio-group
                        formControlName="marital_status"
                        aria-labelledby="radio-group-label"
                        class="ml-2"
                      >
                      <mat-label class="mr-2">Marital Status</mat-label>
                        <mat-radio-button value="single" class="mr10"
                          >Single</mat-radio-button
                        >
                        <mat-radio-button class="mr10" value="married"
                          >Married</mat-radio-button
                        >
                        <mat-radio-button value="divorced"
                          >Divorced</mat-radio-button
                        >
                        <mat-radio-button value="widowed"
                          >Widowed</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Gender*</div>
                    <div class="col-6 py-2">Nationality*</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-radio-group
                        formControlName="gender"
                        aria-labelledby="radio-group-label"
                        class="ml-2"
                      >
                      <mat-label>Gender</mat-label>
                        <mat-radio-button value="male" class="mr10"
                          >Male</mat-radio-button
                        >
                        <mat-radio-button class="mr10" value="female"
                          >Female</mat-radio-button
                        >
                        <mat-radio-button value="other">Other</mat-radio-button>

                        <b
                          class="text-danger"
                          *ngIf="
                            candidateForm.get('gender').hasError('required')
                          "
                          >Gender is required</b
                        >
                      </mat-radio-group>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                      <mat-label>Nationality</mat-label>
                      <input
                          matInput
                          placeholder="Nationality"
                          formControlName="nationality"
                          required
                        />

                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.nationality.invalid &&
                            (cf.nationality.dirty || cf.nationality.touched)
                          "
                        >
                          <div *ngIf="cf.nationality.errors.required">
                            nationality is required
                          </div>
                        </b>
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Father/Husband Name*</div>
                    <div class="col-6 py-2">Relation*</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Father/Husband Name</mat-label>
                        <input
                        placeholder="Father/Husband Name"
                          matInput
                          formControlName="father_husband_name"
                          required
                        />
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.father_husband_name.invalid &&
                            (cf.father_husband_name.dirty ||
                              cf.father_husband_name.touched)
                          "
                        >
                          <div *ngIf="cf.father_husband_name.errors.required">
                            father husband name is required
                          </div>
                        </b>
                    </div>
                    <div class="form-field col-6">
                      <mat-radio-group
                        formControlName="relation"
                        aria-labelledby="radio-group-label"
                        class="ml-2"
                      >
                      <mat-label class="mr-2">Relation</mat-label>
                        <mat-radio-button value="Father" class="mr10"
                          >Father</mat-radio-button
                        >
                        <mat-radio-button class="mr10" value="Husband"
                          >Husband</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">
                      Former/Maiden Names (if applicable)
                    </div>
                    <div class="col-6 py-2">Date of Name Change</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Former/Maiden Names (if applicable)</mat-label>
                        <input matInput placeholder="Former/Maiden Names" formControlName="former_maiden_name" />
                      </mat-form-field>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Date of Name Change</mat-label>
                        <input
                          matInput
                          placeholder="Date of Name Change"
                          [matDatepicker]="date_of_name_change"
                          formControlName="date_of_name_change"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="date_of_name_change"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #date_of_name_change
                        ></mat-datepicker>
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            candidateForm
                              .get('date_of_name_change')
                              .hasError('required')
                          "
                          >Date of name change is required</b
                        >
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Home phone no.</div>
                    <div class="col-6 py-2">Personal email</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Home phone no.</mat-label>
                        <input matInput placeholder="Home phone no." formControlName="home_phone" />
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Office Phone No.</div>
                  </div> -->
                  <div class="row text-center">
                  
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Office Phone No.</mat-label>
                        <input
                          placeholder="Office Phone No."
                          matInput
                          inputmode="numeric"
                          formControlName="office_phone"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- <div class="row py-2 pl-2 bg-secondary">
                    Please mention complete address details
                  </div> -->
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Current Address*</div>
                    <div class="col-6 py-2">Landmark*</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Current Address</mat-label>
                        <textarea
                          matInput
                          placeholder="Current Address"
                          formControlName="current_address"
                          rows="3"
                        ></textarea>
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.current_address.invalid &&
                            (cf.current_address.dirty ||
                              cf.current_address.touched)
                          "
                        >
                          <div *ngIf="cf.current_address.errors.required">
                            Current address is required
                          </div>
                        </b>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Landmark</mat-label>
                        <input
                          matInput
                          placeholder="Landmark"
                          formControlName="current_landmark"
                          required
                        />
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.current_landmark.invalid &&
                            (cf.current_landmark.dirty ||
                              cf.current_landmark.touched)
                          "
                        >
                          <div *ngIf="cf.current_landmark.errors.required">
                            current landmark is required
                          </div>
                        </b>
                    </div>
                  </div>

                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">PIN*</div>
                    <div class="col-6 py-2">Residing Date From</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Pincode</mat-label>
                        <input
                          matInput
                          placeholder="Pincode"
                          formControlName="current_pin"
                          required
                          maxlength="6"
                        />
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.current_pin.invalid &&
                            (cf.current_pin.dirty || cf.current_pin.touched)
                          "
                        >
                          <div *ngIf="cf.current_pin.errors.required">
                            current pin is required
                          </div>
                        </b>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Residing Date From</mat-label>
                        <input
                          matInput
                          placeholder="Residing Date From"
                          [matDatepicker]="residing_from_current"
                          formControlName="residing_from_current"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="residing_from_current"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #residing_from_current
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Residing Date To</div>
                    <div class="col-6 py-2">Contact No.</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Residing Date To</mat-label>
                        <input
                          matInput
                          placeholder="Residing Date To"
                          [matDatepicker]="residing_to_current"
                          formControlName="residing_to_current"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="residing_to_current"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #residing_to_current
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Contact No.</mat-label>
                        <input
                          matInput
                          placeholder="Contact No."
                          formControlName="current_contact"
                        />
                      </mat-form-field>
                    </div>
                  </div>

                  <!-- <div class="row text-center mt-4 text-bloder">
                    <div class="col-6 py-2">Permanent Address*</div>
                    <div class="col-6 py-2">Landmark*</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Permanent Address</mat-label>
                        <textarea
                          matInput
                          placeholder="Permanent Address"
                          formControlName="permanent_address"
                          required
                          rows="3"
                        ></textarea>
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.permanent_address.invalid &&
                            (cf.permanent_address.dirty ||
                              cf.permanent_address.touched)
                          "
                        >
                          <div *ngIf="cf.permanent_address.errors.required">
                            permanent address is required
                          </div>
                        </b>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Permanent Landmark</mat-label>
                        <input
                          matInput
                          placeholder="Permanent Landmark"
                          formControlName="permanent_landmark"
                          required
                        />
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.permanent_landmark.invalid &&
                            (cf.permanent_landmark.dirty ||
                              cf.permanent_landmark.touched)
                          "
                        >
                          <div *ngIf="cf.permanent_landmark.errors.required">
                            permanent landmark is required
                          </div>
                        </b>
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">PIN*</div>
                    <div class="col-6 py-2">Residing Date From</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Pincode</mat-label>
                        <input
                          matInput
                          placeholder="Pincode"
                          formControlName="permanent_pin"
                          maxlength="6"
                          required
                        />
                        </mat-form-field>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.permanent_pin.invalid &&
                            (cf.permanent_pin.dirty || cf.permanent_pin.touched)
                          "
                        >
                          <div *ngIf="cf.permanent_pin.errors.required">
                            permanent pin is required
                          </div>
                        </b>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Residing Date From</mat-label>
                        <input
                          matInput
                          placeholder="Residing Date From"
                          [matDatepicker]="residing_from_permanent"
                          formControlName="residing_from_permanent"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="residing_from_permanent"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #residing_from_permanent
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Residing Date To</div>
                    <div class="col-6 py-2">Contact No.</div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Residing Date To</mat-label>
                        <input
                          matInput
                          placeholder="Residing Date To"
                          [matDatepicker]="residing_to_permanent"
                          formControlName="residing_to_permanent"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="residing_to_permanent"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #residing_to_permanent
                        ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <mat-label>Contact No</mat-label>
                        <input
                          matInput
                          placeholder="Contact No"
                          formControlName="permanent_contact"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row mt-4 py-2 ">
                    <b class="fs-5 text-bloder border-bottom"
                      >EDUCATIONAL QUALIFICATIONS
                    </b>
                  </div>
                  <!-- <div class="row mb-4 text-bloder">
                    <div class="col-12 pt-2">
                      Please provide details of your highest educational
                      qualification attained.
                    </div>
                    <div class="col-12 pb-2">
                      Please attach copies of all years’ marks sheets & degree
                      certificates.
                    </div>
                  </div> -->
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-4 py-2">Degree type*</div>
                    <div class="col-4 py-2">Specialized in degree*</div>
                    <div class="col-4 py-2">
                      Institute universitie name*
                    </div>
                  </div> -->
                  <div class="d-flex align-items-center">
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Degree type</mat-label>
                        <input
                          matInput
                          placeholder="Degree type"
                          [matAutocomplete]="autoDegreeType"
                          formControlName="degree_type"
                        />
                        <mat-autocomplete
                          #autoDegreeType="matAutocomplete"
                          [displayWith]="displayFn"
                        >
                          <mat-option
                            *ngFor="
                              let degreeType of filteredDegreeTypes | async
                            "
                            [value]="degreeType"
                          >
                            {{ degreeType }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <b
                        class="text-danger"
                        *ngIf="
                          cf.degree_type.invalid &&
                          (cf.degree_type.dirty || cf.degree_type.touched)
                        "
                      >
                        <div *ngIf="cf.degree_type.errors.required">
                          Degree type is required
                        </div>
                      </b>
                    </div>
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Specialized in degree</mat-label>
                        <input
                          matInput
                          placeholder="Specialized in degree"
                          [matAutocomplete]="autoSpecializedInDegree"
                          formControlName="specialized_in_degree"
                        />
                        <mat-autocomplete
                          #autoSpecializedInDegree="matAutocomplete"
                          [displayWith]="displayFn"
                        >
                          <mat-option
                            *ngFor="
                              let specializedInDegree of filteredSpecializedInDegrees
                                | async
                            "
                            [value]="specializedInDegree"
                          >
                            {{ specializedInDegree }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <b
                        class="text-danger"
                        *ngIf="
                          cf.specialized_in_degree.invalid &&
                          (cf.specialized_in_degree.dirty ||
                            cf.specialized_in_degree.touched)
                        "
                      >
                        <div *ngIf="cf.specialized_in_degree.errors.required">
                          Specialized in degree is required
                        </div>
                      </b>
                    </div>
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Institute universitie name</mat-label>
                        <input
                          matInput
                          placeholder="Institute universitie name"
                          [matAutocomplete]="autoInstituteUniversity"
                          formControlName="institute_university_name"
                        />
                        <mat-autocomplete
                          #autoInstituteUniversity="matAutocomplete"
                          [displayWith]="displayFn"
                        >
                          <mat-option
                            *ngFor="
                              let instituteUniversity of filteredInstituteUniversities
                                | async
                            "
                            [value]="instituteUniversity"
                          >
                            {{ instituteUniversity }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <b
                        class="text-danger"
                        *ngIf="
                          cf.institute_university_name.invalid &&
                          (cf.institute_university_name.dirty ||
                            cf.institute_university_name.touched)
                        "
                      >
                        <div
                          *ngIf="cf.institute_university_name.errors.required"
                        >
                          Institute university name is required
                        </div>
                      </b>
                    </div>
                  </div>

                  <!-- <div class="row text-center text-bloder">
                    <div class="col-3 py-2">Year of Passing*</div>
                    <div class="col-3 py-2">Date Attended From*</div>
                    <div class="col-3 py-2">Date Attended To*</div>
                    <div class="col-3 py-2">
                      ID / Roll No. / Seat No.*
                    </div>
                  </div> -->
                  <div class="row text-center">
                    <div class="form-field col-3">
                      <mat-form-field class="full-width">
                        <mat-label>Year of Passing</mat-label>
                        <input
                          matInput
                          inputmode="numeric"
                          placeholder="Year of Passing"
                          formControlName="year_of_passing"
                          maxLength="4"
                          required
                        />
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.year_of_passing.invalid &&
                            (cf.year_of_passing.dirty ||
                              cf.year_of_passing.touched)
                          "
                        >
                          <div *ngIf="cf.year_of_passing.errors.required">
                            year of passing is required
                          </div>
                        </b>
                      </mat-form-field>
                    </div>
                    <div class="form-field col-3">
                      <mat-form-field class="full-width">
                        <mat-label>Date Attended From</mat-label>
                        <input
                          matInput
                          placeholder="Date Attended From"
                          [matDatepicker]="attended_to"
                          formControlName="attended_to"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="attended_to"
                        ></mat-datepicker-toggle>
                        <mat-datepicker touchUi #attended_to></mat-datepicker>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.attended_to.invalid &&
                            (cf.attended_to.dirty || cf.attended_to.touched)
                          "
                        >
                          <div *ngIf="cf.attended_to.errors.required">
                            attended to date is required
                          </div>
                        </b>
                      </mat-form-field>
                    </div>
                    <div class="form-field col-3">
                      <mat-form-field class="full-width">
                        <mat-label>Date Attended To</mat-label>
                        <input
                          matInput
                          placeholder="Date Attended To"
                          [matDatepicker]="attended_from"
                          formControlName="attended_from"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="attended_from"
                        ></mat-datepicker-toggle>
                        <mat-datepicker touchUi #attended_from></mat-datepicker>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.attended_from.invalid &&
                            (cf.attended_from.dirty || cf.attended_from.touched)
                          "
                        >
                          <div *ngIf="cf.attended_from.errors.required">
                            attended from date is required
                          </div>
                        </b>
                      </mat-form-field>
                    </div>
                    <div class="form-field col-3">
                      <mat-form-field class="full-width">
                        <mat-label>ID / Roll No. / Seat No.</mat-label>
                        <input
                          matInput
                          placeholder="ID / Roll No. / Seat No."
                          formControlName="id_roll_seat_no"
                          required
                        />
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.id_roll_seat_no.invalid &&
                            (cf.id_roll_seat_no.dirty ||
                              cf.id_roll_seat_no.touched)
                          "
                        >
                          <div *ngIf="cf.id_roll_seat_no.errors.required">
                            id/roll/seat no is required
                          </div>
                        </b>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    

                    <mat-radio-group
                      formControlName="mode"
                  
                      aria-labelledby="radio-group-label"
                    >
                    <mat-label>Mode</mat-label>
                      <mat-radio-button value="Fulltime " class="mx-2"
                        >Fulltime
                      </mat-radio-button>
                      <mat-radio-button class="mx-2" value="Part-time"
                        >Part-time</mat-radio-button
                      >
                      <mat-radio-button value="Distance" class="mx-2"
                        >Distance</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                  <div class="row text-bloder">
                    <div class="col-12 py-2">
                      Please tick mark the documents submitted for this
                      qualification*
                    </div>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-2 py-2">All Year Mark Sheets</div>
                    <div class="col-2 py-2">Final Year Mark Sheet</div>
                    <div class="col-2 py-2">Degree Certificate</div>
                    <div class="col-2 py-2">Provisional Certificate</div>
                    <div class="col-2 py-2">none</div>
                  </div> -->
                  <div class="row text-center text-bloder">
                    <mat-radio-group
                      formControlName="document_submission_status"
                      aria-labelledby="radio-group-label"
                      class="row gap-4"
                    >
                      <!-- <mat-radio-button
                        value="All Year Mark Sheets"
                        class="col-2"
                      >All Year Mark Sheets
                      </mat-radio-button> -->
                      <mat-radio-button
                        value="Final Year Mark Sheet"
                        class="col-2"
                      >Final Year Mark Sheet
                      </mat-radio-button>
                      <mat-radio-button
                        value="Degree Certificate"
                        class="col-2"
                      >Degree Certificate
                      </mat-radio-button>
                      <mat-radio-button
                        class="col-2"
                        value="Provisional Certificate"
                      >Provisional Certificate</mat-radio-button>
                      <mat-radio-button
                        value="none"
                        class="col-2"
                      >none</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Landmark</div>
                  </div>
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          formControlName="qualification_landmark"
                          required
                        />
                        <b
                          class="text-danger"
                          *ngIf="
                            candidateForm
                              .get('qualification_landmark')
                              .hasError('required')
                          "
                          >Qualification landmark is required</b
                        >
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row text-center text-bloder">
                    <div class="col-6 py-2">PIN</div>
                  </div>
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          formControlName="qualification_pin"
                          required
                        />
                        <b
                          class="text-danger"
                          *ngIf="
                            candidateForm
                              .get('qualification_pin')
                              .hasError('required')
                          "
                          >Qualification pin is required</b
                        >
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Residing Date From</div>
                  </div>
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          [matDatepicker]="qualification_residing_from"
                          formControlName="qualification_residing_from"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="qualification_residing_from"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #qualification_residing_from
                        ></mat-datepicker>
                        <b
                          class="text-danger"
                          *ngIf="
                            candidateForm
                              .get('qualification_residing_from')
                              .hasError('required')
                          "
                          >Residing from date is required</b
                        >
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Residing Date To</div>
                  </div>
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          [matDatepicker]="qualification_residing_to"
                          formControlName="qualification_residing_to"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="qualification_residing_to"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          touchUi
                          #qualification_residing_to
                        ></mat-datepicker>
                        <b
                          class="text-danger"
                          *ngIf="
                            candidateForm
                              .get('qualification_residing_to')
                              .hasError('required')
                          "
                          >Residing to date is required</b
                        >
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row text-center text-bloder">
                    <div class="col-6 py-2">Contact No.</div>
                  </div>
                  <div class="row text-center">
                    <div class="form-field col-6">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          formControlName="qualification_contact"
                          required
                        />
                        <b
                          class="text-danger"
                          *ngIf="
                            candidateForm
                              .get('qualification_contact')
                              .hasError('required')
                          "
                          >Qualification contact is required</b
                        >
                      </mat-form-field>
                    </div>
                  </div> -->

                  <div class="row mt-4 py-2">
                    <b class="fs-5 text-bloder"
                      ><input
                        type="checkbox"
                        style="width: 20px; height: 20px"
                        formControlName="is_fresher"
                        (change)="toggleFresherStatus()"
                      />&nbsp; I'm Fresher
                    </b>
                  </div>
                  <div *ngIf="!isFresher">
                    <div class="row mt-4 py-2 ">
                      <b class=" border-bottom fs-5 text-bloder"
                        >EMPLOYMENT HISTORY
                      </b>
                    </div>
                    <div class="row  mb-4">
                      <div class="col-12 text-bloder fs-5 pt-2">
                        Please provide employment history of all employments
                        prior to 3i Infotech
                      </div>
                      <div class="col-12 pb-2">
                        Ensure that you are descriptive wherever necessary; e.g.
                        if the company has closed/ceased operations/moved
                        location, do mention it.
                      </div>
                      <div class="col-12 text-bloder fs-5 pb-2">
                        Employee Code/ ID/ Number of your previous employer is
                        mandatory.
                      </div>
                      <div class="col-12 pb-2">
                        Start with your most recent/current employer, followed
                        by details of the employment preceding that
                      </div>
                    </div>
                    <div formArrayName="employments">
                      <div
                        *ngFor="
                          let employment of candidateForm.get('employments')
                            .value;
                          let aIndex = index;
                          trackBy: trackByIndex
                        "
                      >
                        <div [formGroupName]="aIndex">
                          <div class="row w-100">
                            <div class="row mt-2 py-2 border">
                              <b class="fs-5 col-11 text-bloder"
                                >Employer {{ aIndex + 1 }}
                              </b>
                              <b
                                class="fas col fa-times-circle position-relative"
                                style="cursor: pointer; font-size: 35px"
                                (click)="removeEmployments(aIndex)"
                              ></b>
                            </div>
                            <div
                              class="row text-center text-bloder"
                            >
                              <div class="col-4 py-2">
                                Name of Employer (Current /Most Recent Employer)
                              </div>
                              <div class="col-4 py-2">
                                Company Contact Nos.
                              </div>
                              <div class="col-4 py-2">Address</div>
                            </div>
                            <div class="row text-center">
                              <div class="form-field col-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="employer_name"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('employer_name')
                                      .hasError('required')
                                  "
                                >
                                  Employer name is required
                                </b>
                              </div>
                              <div class="form-field col-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="contact_number"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('contact_number')
                                      .hasError('required')
                                  "
                                >
                                  contact number is required
                                </b>
                              </div>
                              <div class="form-field col-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="address"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('address')
                                      .hasError('required')
                                  "
                                >
                                  address is required
                                </b>
                              </div>
                            </div>
                            <div
                              class="row text-center text-bloder"
                            >
                              <div class="col-3 py-2">Employee Code</div>
                              <div class="col-3 py-2">Designation</div>
                              <div class="col-3 py-2">Grade</div>
                              <div class="col-3 py-2">Department</div>
                            </div>
                            <div class="row text-center">
                              <div class="form-field col-3">
                                <input
                                  type="number"
                                  class="form-control"
                                  formControlName="employee_code"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('employee_code')
                                      .hasError('required')
                                  "
                                >
                                  employee code is required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="designation"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('designation')
                                      .hasError('required')
                                  "
                                >
                                  designation is required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="grade"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('grade')
                                      .hasError('required')
                                  "
                                >
                                  grade is required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="department"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('department')
                                      .hasError('required')
                                  "
                                >
                                  department is required
                                </b>
                              </div>
                            </div>
                            <div
                              class="row text-center text-bloder"
                            >
                              <div class="col-3 py-2">
                                Employment Period From
                              </div>
                              <div class="col-3 py-2">Employment Period To</div>
                              <div class="col-3 py-2">First Salary Drawn</div>
                              <div class="col-3 py-2">Last Salary Drawn</div>
                            </div>
                            <div class="row text-center">
                              <div class="form-field col-3">
                                <input
                                  type="date"
                                  class="form-control"
                                  formControlName="employment_period_from"
                                />

                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('employment_period_from')
                                      .hasError('required')
                                  "
                                >
                                  employment period from is required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="date"
                                  class="form-control"
                                  formControlName="employment_period_to"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('employment_period_to')
                                      .hasError('required')
                                  "
                                >
                                  employment period to is required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="first_salary_drawn"
                                />

                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('first_salary_drawn')
                                      .hasError('required')
                                  "
                                >
                                  first salary drawn is required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="last_salary_drawn"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('last_salary_drawn')
                                      .hasError('required')
                                  "
                                >
                                  last salary drawn is required
                                </b>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col-3 text-center text-bloder py-2"
                              >
                                Type of Employment
                              </div>

                              <mat-radio-group
                                formControlName="employment_type"
                                aria-labelledby="radio-group-label"
                                class="row col-9"
                              >
                                <mat-radio-button
                                  value="Permanent"
                                  class="col-4"
                                  >Permanent
                                </mat-radio-button>
                                <mat-radio-button
                                  class="col-4"
                                  value="Temporary"
                                  >Temporary</mat-radio-button
                                >
                                <mat-radio-button
                                  value="Contractual"
                                  class="col-4"
                                  >Contractual</mat-radio-button
                                >
                              </mat-radio-group>
                            </div>
                            <div
                              class="row text-center text-bloder"
                            >
                              <div class="col-4 py-2">
                                Details of Agency (if deployed from another
                                agency)
                              </div>
                              <div class="col-4 py-2">
                                Reason for Leaving
                              </div>
                              <div class="col-4 py-2">
                                Duties & Responsibilities
                              </div>
                            </div>
                            <div class="row text-center">
                              <div class="form-field col-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="agency_details"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('agency_details')
                                      .hasError('required')
                                  "
                                >
                                  agency details is required
                                </b>
                              </div>
                              <div class="form-field col-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="reason_for_leaving"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('reason_for_leaving')
                                      .hasError('required')
                                  "
                                >
                                  reason for leaving is Required
                                </b>
                              </div>
                              <div class="form-field col-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="duties_responsibilities"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('duties_responsibilities')
                                      .hasError('required')
                                  "
                                >
                                  duties responsibilities is Required
                                </b>
                              </div>
                            </div>
                            <div
                              class="row text-center text-bloder"
                            >
                              <div class="col-3 py-2">
                                Reporting Manager
                              </div>
                              <div class="col-3 py-2">
                                Designation & Department
                              </div>
                              <div class="col-3 py-2">
                                Contact No. (Include Extn. #)
                              </div>
                              <div class="col-3 py-2">Email id</div>
                            </div>
                            <div class="row text-center">
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="reporting_name"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('reporting_name')
                                      .hasError('required')
                                  "
                                >
                                  reporting name is Required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="reporting_designation_department"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('reporting_designation_department')
                                      .hasError('required')
                                  "
                                >
                                  reporting designation & department is Required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="reporting_number"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('reporting_number')
                                      .hasError('required')
                                  "
                                >
                                  reporting number is Required
                                </b>
                              </div>
                              <div class="form-field col-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="reporting_email"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('reporting_email')
                                      .hasError('required')
                                  "
                                >
                                  reporting email is Required
                                </b>
                              </div>
                            </div>
                            <div
                              class="row text-center text-bloder"
                            >
                              <div class="col-6 py-2">
                                Can a reference be taken now?
                              </div>
                              <div class="col-6 py-2">
                                If ‘No’, please indicate date when reference can
                                be taken?
                              </div>
                            </div>
                            <div class="row text-center">
                              <div class="form-field col-6">
                                <mat-radio-group
                                  formControlName="reference_availability"
                                  aria-labelledby="radio-group-label"
                                  class="row"
                                >
                                  <mat-radio-button value="true" class="col-6"
                                    >Yes</mat-radio-button
                                  >
                                  <mat-radio-button class="col-6" value="false"
                                    >No</mat-radio-button
                                  >
                                </mat-radio-group>
                              </div>
                              <div class="form-field col-6">
                                <input
                                  type="date"
                                  class="form-control"
                                  formControlName="reference_availability_date"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employments.controls &&
                                    employments.controls[aIndex]
                                      ?.get('reference_availability_date')
                                      .hasError('required')
                                  "
                                >
                                  reference availability date is Required
                                </b>
                              </div>
                            </div>
                            <div class="row mt-4 py-2 border">
                              <b class="fs-5 text-bloder"
                                >Please tick mark the documents submitted for
                                this qualification:</b
                              >
                            </div>

                            <div
                              class="row text-center text-bloder"
                            >
                              <mat-radio-group
                                formControlName="documentsSubmitted"
                                aria-labelledby="radio-group-label"
                                class="row"
                              >
                                <mat-radio-button
                                  value="Relieving Letter"
                                  class="col-2"
                                  >Relieving Letter</mat-radio-button
                                >
                                <mat-radio-button
                                  value="Service Certificate"
                                  class="col-2"
                                >
                                  Service Certificate</mat-radio-button
                                >
                                <mat-radio-button
                                  value="Appointment Letter"
                                  class="col-2"
                                >
                                  Appointment Letter</mat-radio-button
                                >
                                <mat-radio-button
                                  value="Salary Slip"
                                  class="col-2"
                                >
                                  Salary Slip</mat-radio-button
                                >
                                <mat-radio-button value="Others" class="col-2">
                                  Others</mat-radio-button
                                >
                                <mat-radio-button value="none" class="col-2"
                                  >None</mat-radio-button
                                >
                              </mat-radio-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row w-100"
                      style="
                        border-bottom: 1px solid #000000;
                        padding-bottom: 10px;
                      "
                    >
                      <div class="col-11"></div>
                      <div class="col text-right" style="text-align: right">
                        <button
                          mat-raised-button
                          color="accent"
                          (click)="addEmployments()"
                          type="button"
                        >
                          Add +
                        </button>
                      </div>
                    </div>

                    <div class="row mt-4 py-2">
                      <b class=" border-bottomfs-5 text-bloder"
                        >GAPS IN EMPLOYMENT
                      </b>
                    </div>
                    <div class="row mb-4 text-bloder">
                      <div class="col-12 py-2">
                        Please provide details of all gaps of 1 month or more
                        that you may have between your past 2 employments or
                        between leaving the last employment and joining 3i
                        Infotech
                      </div>
                    </div>
                    <div class="row text-center text-bloder">
                      <div class="col-3 py-2">Gap From</div>
                      <div class="col-3 py-2">Gap To</div>
                      <div class="col-3 py-2">Gap Period (in months)</div>
                      <div class="col-3 py-2">Reason</div>
                    </div>

                    <div formArrayName="employment_gaps" class="row w-100">
                      <div
                        *ngFor="
                          let employment_gap of candidateForm.get(
                            'employment_gaps'
                          ).value;
                          let aIndex = index;
                          trackBy: trackByIndex
                        "
                        class="row w-100"
                      >
                        <div [formGroupName]="aIndex" class="row w-100">
                          <div class="row w-100">
                            <div class="col-md-3">
                              <div class="form-field">
                                <input
                                  type="date"
                                  class="form-control"
                                  formControlName="gap_from"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employmentGaps.controls &&
                                    employmentGaps.controls[aIndex]
                                      ?.get('gap_from')
                                      .hasError('required')
                                  "
                                >
                                  Gap From date is required
                                </b>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-field">
                                <input
                                  type="date"
                                  class="form-control"
                                  formControlName="gap_to"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employmentGaps.controls &&
                                    employmentGaps.controls[aIndex]
                                      ?.get('gap_to')
                                      .hasError('required')
                                  "
                                >
                                  Gap to date is required
                                </b>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-field">
                                <input
                                  type="month"
                                  class="form-control"
                                  formControlName="gap_period"
                                />
                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employmentGaps.controls &&
                                    employmentGaps.controls[aIndex]
                                      ?.get('gap_period')
                                      .hasError('required')
                                  "
                                >
                                  Gap period date is required
                                </b>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-field">
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="reason"
                                />

                                <b
                                  class="text-danger"
                                  *ngIf="
                                    employmentGaps.controls &&
                                    employmentGaps.controls[aIndex]
                                      ?.get('reason')
                                      .hasError('required')
                                  "
                                  >Reason is required</b
                                >
                              </div>
                            </div>
                            <div class="col align-middle text-center">
                              <span
                                class="fas mt-2 fa-times-circle position-relative"
                                style="cursor: pointer; font-size: 24px"
                                (click)="removeEmploymentGap(aIndex)"
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row w-100"
                      style="
                        border-bottom: 1px solid #000000;
                        padding-bottom: 10px;
                      "
                    >
                      <div class="col"></div>
                      <div class="col text-right" style="text-align: right">
                        <button
                          mat-raised-button
                          color="accent"
                          (click)="addEmploymentGap()"
                          type="button"
                        >
                          Add +
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4 py-2 ">
                    <b class="border-bottomfs-5 text-bloder"
                      >OTHER INFORMATION
                    </b>
                  </div>
                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Are you currently engaged in any other business either as
                      a proprietor, partner, officer, director, trustee,
                      employee, agent or otherwise. If yes, please give details.
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="engaged_in_other_business"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Have you been dismissed from the services of any previous
                      employer(s)? If yes, please give details.
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="dismissed_from_previous_employment"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Have you ever been convicted in a court of law or of a
                      criminal offence? If yes, please give details and status
                      of prosecutions against you.
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="convicted_of_criminal_offence"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Have you ever had any civil judgments made against you? If
                      yes, please give details.
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="civil_judgments_against_you"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <!-- <div class="row mt-4 py-2 ">
                    <b class="text-white fs-5 text-bloder"
                      >INFORMATION RELEASE FORM</b
                    >
                  </div>
                  <div class="row mt-4 py-2 border">
                    <b
                      >I hereby authorize 3i Infotech Ltd. and /or any of its
                      subsidiaries or affiliates (“3i Infotech”) and any person
                      or organization appointed by 3i Infotech, to contact any
                      former employers, educational institutes and all such
                      sources as may be required to verify information provided
                      in my resume and /or application of employment and to
                      carry out all Background Checks as may be deemed
                      appropriate per 3i Infotech selection procedure.
                      <br />
                      <br />
                      I hereby authorize former employers, agencies, educational
                      institutes and all persons who may have information
                      relevant to this enquiry to release the information to 3i
                      Infotech or their appointed agents. I release all persons
                      from liability on account of such disclosure. I am willing
                      that a photocopy of this authorization be accepted with
                      the same authority as the original.</b
                    >
                  </div> -->
                  <!-- <div class="row text-center text-bloder">
                    <div class="col-4 py-2">Date*</div>
                    <div class="col-4 py-2">Name*</div>
                  </div>
                  <div class="row text-center">
                    <div class="form-field col-4">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          [matDatepicker]="release_date"
                          formControlName="release_date"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="release_date"
                        ></mat-datepicker-toggle>
                        <mat-datepicker touchUi #release_date></mat-datepicker>
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.release_date.invalid &&
                            (cf.release_date.dirty || cf.release_date.touched)
                          "
                        >
                          <div *ngIf="cf.release_date.errors.required">
                            release date is required
                          </div>
                        </b>
                      </mat-form-field>
                    </div>
                    <div class="form-field col-4">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          formControlName="release_name"
                          required
                        />
                        <b
                          class="text-danger"
                          *ngIf="
                            cf.release_name.invalid &&
                            (cf.release_name.dirty || cf.release_name.touched)
                          "
                        >
                          <div *ngIf="cf.release_name.errors.required">
                            release name is required
                          </div>
                        </b>
                      </mat-form-field>
                    </div>
                  </div> -->
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Health Declaration Form*
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="page1" id="medicalForm">
                  <div class="row py-2">
                    <b class="fs-6"
                      >The purpose of this Health Declaration is to assess your
                      fitness to undertake the position which you have been
                      offered. The information you provide will remain
                      confidential.
                    </b>
                  </div>
                  <div
                    class="row fs-6 text-center p-2 text-bloder"
                  >
                    <div class="col-8">Previous Medical History</div>
                    <div class="col-4">Details</div>
                  </div>
                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Are you experiencing any health problems If yes, please
                      give details below:
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="health_problems_details"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Have you had any significant illness or time off work in
                      the last 12 months If yes, please give details below:
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="significant_illness_details"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Do you have any health problems that you think may affect
                      your performance or safety in the job you have been
                      offered?
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="health_problems_affect_performance"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="row mb-4 text-bloder">
                    <div class="col-8 py-2">
                      Do you require any special aids/adaptions to assist you at
                      work, whether or not you have a disability?
                    </div>
                    <div class="form-field col-4">
                      <mat-radio-group
                        formControlName="special_aids_required"
                        aria-labelledby="radio-group-label"
                        class="row"
                      >
                        <mat-radio-button value="true" class="col-6"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button class="col-6" value="false"
                          >No</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                  <!-- <div class="row p-2 mb-4 text-bloder">
                    <div class="form-field col-md-4">
                      <label class="file-upload-label">
                        <div class="file-upload-design">
                          <img
                            *ngIf="covidOnePhoto"
                            width="80"
                            height="60"
                            [src]="covidOnePhoto"
                          />
                          <svg
                            *ngIf="!covidOnePhoto"
                            viewBox="0 0 640 512"
                            height="1em"
                          >
                            <path
                              d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                            ></path>
                          </svg>
                          <span class="browse-button"
                            >Covid vaccine dose 1 Certificate
                          </span>
                        </div>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .pdf"
                          (change)="selectCovidOnePhoto($event)"
                        />
                      </label>
                    </div>
                    <div class="form-field col-md-4">
                      <label class="file-upload-label">
                        <div class="file-upload-design">
                          <img
                            *ngIf="covidTwoPhoto"
                            width="80"
                            height="60"
                            [src]="covidTwoPhoto"
                          />
                          <svg
                            *ngIf="!covidTwoPhoto"
                            viewBox="0 0 640 512"
                            height="1em"
                          >
                            <path
                              d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                            ></path>
                          </svg>
                          <span class="browse-button"
                            >Covid vaccine dose 1 Certificate
                          </span>
                        </div>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .pdf"
                          (change)="selectCovidTwoPhoto($event)"
                        />
                      </label>
                    </div>
                  </div> -->
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </mat-accordion>
        <div class="d-flex mt-2 gap-3">
          <button
            type="submit"
            class="btn btn-success w-100"
            [disabled]="
              !isAadharVerified || !isMobileVerified || !isMailVerified
            "
          >
            Submit ✔️
          </button>
          <b class="btn btn-info w-100" (click)="saveBgv()"> Save 🗃️ </b>
        </div>
      </form>
      <div class="d-flex mt-2 gap-3">
        <!-- [disabled]="!this.candidateForm.valid" -->
        <button class="btn btn-secondary w-100" (click)="printBgv()">
          Print BGV 🖨️
        </button>
        <!-- [disabled]="!this.candidateForm.valid" -->
        <button class="btn btn-secondary w-100" (click)="printMedical()">
          Print Health Declaration 🖨️
        </button>
      </div>
    </div>
  </body>
</html>
