import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../shared/services/alert.service';
import { CommonService } from '../shared/services/common.service';

@Component({
  // selector: 'router-outlet',
  templateUrl: './login.component.html',
  styleUrls: ['./login.scss'],
})
export class LoginComponent implements OnInit {
  title = 'Login Component';
  user: any = {};
  loading = false;
  error = '';
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private commonService: CommonService,
    private alertSerive: AlertService,
    private activatedRoute: ActivatedRoute
  ) {
    this.user = {
      email: '',
      password: '',
    };
  }

  ngOnInit() {}

  login() {    
    this.loading = true;
    this.commonService.backendAPICall('/auth/login',this.user).subscribe(
      (result) => {
        if (result['token']) {
          localStorage.setItem('token', result['token']);
          localStorage.setItem('isLogged', 'true');
          localStorage.setItem('user', JSON.stringify(result['user']));          
          this.router.navigate(['/admin/dashboard']);
        } else {
          this.alertSerive.create(
            'Authentication Failed',
            'error',
            0,
            result['error']
          );
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.alertSerive.create('API Error', 'error', 0, 'login fail! please check creds.');
      }
    );
  }

}
