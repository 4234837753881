import { HttpClient } from "@angular/common/http";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component } from "@angular/core";
import { AlertService } from "../shared/services/alert.service";
import { CommonService } from "../shared/services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "candidate-form",
  templateUrl: "./candidate-form.component.html",
  styleUrls: ["./candidate-form.component.css"],
})
export class CandidateFormComponent {
  candidateForm: FormGroup;
  passHide = true;
  loading = false;
  panPhoto: any;
  aadharPhoto: any;
  bankChequePhoto: any;
  profilePhoto: any;
  SignPhoto: any;
  EmpDocPhoto: any;
  QuliDocPhoto: any;
  covidOnePhoto: any;
  covidTwoPhoto: any;
  //pan verify
  isPanVerified = false;
  panVerMsg: string;
  //BANK verify
  isBankVerified = false;
  BankVerMsg: string;
  citySettings: any = {};
  championPackageTypeSettings: any = {};
  states: any;
  champions: any;
  tempUserToken: any;
  //adhar verify
  aadharOtpSent = false;
  isAadharVerified = false;
  aadharVerMsg: string;
  aadharRequestid: string;
  aadharTransId: string;
  //for mobile otp
  mobileOtpSent = false;
  isMobileVerified = false;
  mobileVerMsg: string;
  generatedOTP: string;

  //for mail otp
  mailOtpSent = false;
  isMailVerified = false;
  mailVerMsg: string;
  generatedMailOTP: string;
  imgLoading: { [key: string]: boolean } = {};
  //payment
  showPayment: boolean = true;
  paymentData: any;
  imageURLs: any = {};
  hasNoPan: boolean = false;
  hasNoBank: boolean = false;
  imageFields = [
    // "profile_photo",
    // "pan_photo",
    // "aadhar_photo",
    "bank_cheque_photo",
    "qualification_documents_photo",
    "employment_documents_photo",
    "release_signature",
    // "covid_cert_one",
    // "covid_cert_two",
  ];
  token: string;
  userId: string | null = null;
  sessionId: string | null = null;
  candidateData: any;
  isFresher: boolean = false;
  degreeTypes: string[] = ['Bachelor\'s Degree', 'Master\'s Degree', 'Ph.D.'];
  specializedInDegrees: string[] = ['Computer Science', 'Engineering', 'Business Administration'];
  instituteUniversities: string[] = ['University of Technology', 'University of Science', 'University of Business'];

  filteredDegreeTypes: Observable<string[]>;
  filteredSpecializedInDegrees: Observable<string[]>;
  filteredInstituteUniversities: Observable<string[]>;

  constructor(
    private commonService: CommonService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.token = localStorage.getItem("token");
    this.route.queryParamMap.subscribe((params) => {
      this.userId = params.get("user_id");
      this.sessionId = params.get("session_id");
      if (this.userId && this.sessionId) {
        // Fetch the candidate data using user_id and session_id
        this.getCandidateData(this.userId, this.sessionId);
      } else {
        // Handle the case for missing parameters
        console.error("Missing user_id or session_id in query parameters");
      }
    });
    this.candidateForm = this.fb.group({
      id: null,
      first_name: ["", Validators.required],
      middle_name: [""],
      last_name: ["", Validators.required],
      nick_name: [""],
      gender: ["male", Validators.required],
      father_husband_name: ["", Validators.required],
      relation: ["Father"],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9-]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      otp: [""],
      mobile_status: ["pending"],
      email_status: ["pending"],
      pan_status: ["pending"],
      aadhar_status: ["pending"],
      bank_ac_status: ["pending"],
      mailOtp: [""],
      email: ["", [Validators.required, Validators.email]],
      pan_number: [
        { value: "", disabled: this.isPanVerified },
        [ Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]{1}")],
      ],
      aadhar_number: [
        "",
        [
          
          Validators.pattern("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$"),
        ],
      ],
      aadharOtp: [""],

      bank_ac_number: [
        { value: "", disabled: this.isBankVerified },
        Validators.required,
      ],
      bank_ac_name: [{ value: "", disabled: this.isBankVerified }],
      bank_branch: [""],
      bank_name: [""],

      bank_ifsc: [
        { value: "", disabled: this.isBankVerified },
        Validators.required,
      ],

      pincode: [""],

      date_of_joining: [""],
      employee_code: [""],
      designation: [""],
      department: [""],
      department_location: [""],
      date_of_birth: ["", Validators.required],
      marital_status: ["single", Validators.required],
      nationality: ["", Validators.required],
      former_maiden_name: [""],
      date_of_name_change: [""],
      home_phone: [""],
      personal_email: ["", Validators.email],
      mobile_phone: [
        "",
        [
          Validators.pattern("^[0-9-]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      office_phone: [""],
      current_address: ["", Validators.required],
      permanent_address: ["", Validators.required],
      current_landmark: ["", Validators.required],
      permanent_landmark: ["", Validators.required],
      current_pin: ["", Validators.required],
      permanent_pin: ["", Validators.required],
      residing_from_current: [""],
      residing_to_current: [""],
      residing_from_permanent: [""],
      residing_to_permanent: [""],
      current_contact: [""],
      permanent_contact: [""],

      degree_type: ["", Validators.required],
      specialized_in_degree: ["", Validators.required],
      institute_university_name: ["", Validators.required],
      year_of_passing: ["", Validators.required],
      attended_to: ["", Validators.required],
      attended_from: ["", Validators.required],
      id_roll_seat_no: ["", Validators.required],
      mode: ["Fulltime", Validators.required],
      document_submission_status: ["All Year Mark Sheets", Validators.required],
      // qualification_landmark: [""],
      // qualification_pin: [""],
      // qualification_residing_from: [""],
      // qualification_residing_to: [""],
      // qualification_contact: [""],

      employments: this.fb.array([]),
      employment_gaps: this.fb.array([]),

      engaged_in_other_business: [false, Validators.required],
      dismissed_from_previous_employment: [false, Validators.required],
      convicted_of_criminal_offence: [false, Validators.required],
      civil_judgments_against_you: [false, Validators.required],

      health_problems_details: [false, Validators.required],
      significant_illness_details: [false, Validators.required],
      health_problems_affect_performance: [false, Validators.required],
      special_aids_required: [false, Validators.required],
      is_fresher: [false, Validators.required],

      release_date: ["", Validators.required],
      release_name: ["", Validators.required],

      //images
      profile_photo: [""],
      pan_photo: [""],
      bank_cheque_photo: [""],
      aadhar_photo: [""],
      qualification_documents_photo: [""],
      employment_documents_photo: [""],
      release_signature: ["", Validators.required],
      // covid_cert_two: [""],
      // covid_cert_one: [""],
    });
    this.imageFields.forEach((field) => {
      this.candidateForm.addControl(field, this.fb.control(""));
    });
    // Add default rows
    // this.addEmployments();
    // this.addEmploymentGap();

    this.filteredDegreeTypes = this.candidateForm
      .get("degree_type")
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value, this.degreeTypes))
      );

    this.filteredSpecializedInDegrees = this.candidateForm
      .get("specialized_in_degree")
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value, this.specializedInDegrees))
      );

    this.filteredInstituteUniversities = this.candidateForm
      .get("institute_university_name")
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value, this.instituteUniversities))
      );
  }

  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  displayFn(value: string): string {
    return value ? value : "";
  }

  // convenience getter for easy access to form fields
  get cf() {
    return this.candidateForm.controls;
  }

  get employments(): FormArray {
    return this.candidateForm.get("employments") as FormArray;
  }

  get employmentGaps(): FormArray {
    return this.candidateForm.get("employment_gaps") as FormArray;
  }

  addEmployments() {
    let employmentsArr = this.candidateForm.get("employments") as FormArray;
    employmentsArr.push(
      this.fb.group({
        employer_name: ["", Validators.required],
        contact_number: ["", Validators.required],
        address: ["", Validators.required],
        employee_code: ["", Validators.required],
        designation: ["", Validators.required],
        grade: ["", Validators.required],
        department: ["", Validators.required],
        employment_period_from: ["", Validators.required],
        employment_period_to: ["", Validators.required],
        first_salary_drawn: ["", Validators.required],
        last_salary_drawn: ["", Validators.required],
        employment_type: ["Permanent", Validators.required],
        agency_details: ["", Validators.required],
        reason_for_leaving: ["", Validators.required],
        duties_responsibilities: ["", Validators.required],
        reporting_name: ["", Validators.required],
        reporting_designation_department: ["", Validators.required],

        reporting_number: ["", Validators.required],

        reporting_email: ["", Validators.required],
        reference_availability: [false, Validators.required],
        reference_availability_date: ["", Validators.required],
        documentsSubmitted: ["Relieving Letter", Validators.required],
      })
    );
  }

  removeEmployments(atIndex) {
    let employmentsArr = this.candidateForm.get("employments") as FormArray;
    employmentsArr.removeAt(atIndex);
  }
  addEmploymentGap() {
    let gapArr = this.candidateForm.get("employment_gaps") as FormArray;
    gapArr.push(
      this.fb.group({
        gap_from: ["", Validators.required],
        gap_to: ["", Validators.required],
        gap_period: ["", Validators.required],
        reason: ["", Validators.required],
      })
    );
  }

  removeEmploymentGap(atIndex) {
    let gapArr = this.candidateForm.get("employment_gaps") as FormArray;
    gapArr.removeAt(atIndex);
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
  // mobile verification
  sendOTP() {
    const mobileNumber = this.candidateForm.get("mobile").value;
    const UserName = this.candidateForm.get("first_name").value || "User";
    if (mobileNumber === null || mobileNumber === "") {
      window.alert("Enter Mobile Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to send OTP
    this.commonService
      .backendAPICall("/send-otp", {
        name: UserName,
        mobile: mobileNumber,
      })
      .subscribe(
        (response) => {
          this.generatedOTP = response["otp"];
          this.mobileOtpSent = true;
          this.mobileVerMsg = "Mobile Otp sent Successfully";
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          console.error("Error sending OTP", error);
          // Hide loading
          this.hideLoading();
          window.alert("Error sending OTP");
        }
      );
  }

  verifyOTP() {
    const enteredOTP = this.candidateForm.get("otp").value;
    // Show loading
    if (enteredOTP === null && enteredOTP === "") {
      window.alert("Enter OTP!");
      return;
    }
    this.showLoading();
    if (enteredOTP == this.generatedOTP) {
      this.mobileOtpSent = false;
      this.isMobileVerified = true;
      this.mobileVerMsg = "Mobile number is verified.";
      this.candidateForm.get("mobile_status").setValue("verified");
      // Hide loading
      this.hideLoading();
    } else {
      // Hide loading
      this.hideLoading();
      window.alert("OTP is not correct!");
    }
  }

  sendMailOTP() {
    const email = this.candidateForm.get("email").value;
    if (email === null || email === "") {
      window.alert("Email is not filled");
      return;
    }
    this.generatedMailOTP =
      Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + "";
    // Show loading
    this.showLoading();
    // API to send OTP
    const emailSubject = "Hovercraft Verification.";
    const template = `<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n  <meta charset=\"UTF-8\">\n  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n  <title>OTP Verification</title>\n  <style>\n    body {\n      font-family: Arial, sans-serif;\n      background-color: #f4f4f4;\n      color: #333;\n    }\n    .container {\n      max-width: 600px;\n      margin: 0 auto;\n      padding: 20px;\n      background-color: #fff;\n      border-radius: 5px;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    }\n    h1 {\n      color: #4285f4;\n    }\n  </style>\n</head>\n<body>\n  <div class=\"container\">\n    <h1>OTP Verification</h1>\n    <p>Hello  User,</p>\n    <p>Your OTP (One-Time Password) for verification is: <strong>${this.generatedMailOTP}</strong></p>\n    <p>Please use this OTP to complete the verification process.</p>\n\n    <p>If you didn't request this OTP, please ignore this email.</p>\n\n    <footer>\n      <p>Best regards,<br> Hovercraft Verification Team</p>\n    </footer>\n  </div>\n</body>\n</html>`;

    this.commonService
      .backendAPICall("/send-mail", {
        sendTo: email,
        emailSubject: emailSubject,
        template: template,
      })
      .subscribe(
        (response) => {
          this.mailOtpSent = true;
          this.mailVerMsg = "Mail Otp sent Successfully";
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          console.error("Error sending OTP", error);
          // Hide loading
          this.hideLoading();
        }
      );
  }

  verifyMailOTP() {
    const enteredOTP = this.candidateForm.get("mailOtp").value;

    if (enteredOTP == null) {
      window.alert("Enter OTP!");
      return;
    }
    // Show loading
    this.showLoading();

    if (enteredOTP == this.generatedMailOTP) {
      this.mailOtpSent = false;
      this.isMailVerified = true;
      this.mailVerMsg = "Email is verified.";
      this.candidateForm.get("email_status").setValue("verified");
      // Hide loading
      this.hideLoading();
    } else {
      // Hide loading
      this.hideLoading();
      window.alert("OTP is not correct!");
    }
  }

  verifyPan() {
    const panNumber = this.candidateForm.get("pan_number").value;
    if (panNumber == null || panNumber.trim() === "") {
      window.alert("Enter PAN Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/documents-verification-old", {
        verFor: "pan-verification",
        bodyData: {
          requestid: "123456",
          pancard: panNumber,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS") {
            if (response.data.clientStatus.includes("Valid")) {
              this.isPanVerified = true;
              this.panVerMsg = "Pan Card is Verified";
              this.candidateForm.get("pan_status").setValue("verified");
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  sendAadharOtp() {
    const aadharNumber = this.candidateForm
      .get("aadhar_number")
      .value.toString();
    if (aadharNumber == null || aadharNumber.trim() === "") {
      window.alert("Enter Aadhar Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/documents-verification-old", {
        verFor: "aadhaar-otp",
        bodyData: {
          aadhaar: aadharNumber,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS" || response.status == 200) {
            if (response.data) {
              this.aadharOtpSent = true;
              this.aadharVerMsg = "Aadhar Otp sent Successfully";
              this.aadharTransId = response.data.tran_id;
              this.aadharRequestid = response.requestid;
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  aadharVerify() {
    const aadharOtp = this.candidateForm.get("aadharOtp").value.toString();
    if (aadharOtp == null || aadharOtp.trim() === "") {
      window.alert("Enter Aadhar Otp!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/documents-verification-old", {
        verFor: "aadhaar-verification",
        bodyData: {
          otp: aadharOtp,
          tran_id: this.aadharTransId,
          request_id: this.aadharRequestid,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS" || response.status == 200) {
            if (response.data) {
              this.aadharOtpSent = false;
              this.isAadharVerified = true;
              this.aadharRequestid = null;
              this.aadharTransId = null;
              this.aadharVerMsg = "Aadhar number is verified.";
              this.candidateForm.get("aadhar_status").setValue("verified");
              this.candidateForm.get("first_name").setValue(response.data.name);
              this.candidateForm
                .get("gender")
                .setValue(
                  response.data.gender == "M"
                    ? "male"
                    : response.data.gender == "F"
                    ? "female"
                    : "other"
                );
              if (response.data.mobile && response.data.email) {
                this.candidateForm.get("mobile").setValue(response.data.mobile);
                this.candidateForm.get("email").setValue(response.data.email);
              }

              let addressComponents = response.data.address
                .split(",")
                .map((component) => component.trim())
                .reverse();

              let address = response.data.address
                .split(",")
                .slice(0, -3)
                .join(", ")
                .toString();

              if (addressComponents.length > 0) {
                // this.candidateForm
                //   .get("state")
                //   .setValue(addressComponents[1].toString());
                // this.candidateForm
                //   .get("city")
                //   .setValue(addressComponents[2].toString());
                this.candidateForm
                  .get("pincode")
                  .setValue(addressComponents[0]);
              }
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  verifyBankAccount() {
    const bank_ifsc = this.candidateForm.get("bank_ifsc").value.toString();
    const bank_ac_number = this.candidateForm
      .get("bank_ac_number")
      .value.toString();
    if (
      bank_ifsc == null ||
      (bank_ifsc.trim() === "" && bank_ifsc == null) ||
      bank_ac_number.trim() === ""
    ) {
      window.alert("Enter Account Number and IFSC!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/documents-verification-old", {
        verFor: "bank-verification",
        bodyData: {
          acc_nummber: bank_ac_number,
          ifsc_number: bank_ifsc,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS") {
            if (response.data.verification_status == "VERIFIED") {
              this.isBankVerified = true;
              this.BankVerMsg = "Bank Account is Verified";
              this.candidateForm
                .get("bank_ac_name")
                .setValue(response.data.beneficiary_name);
              this.candidateForm
                .get("bank_ac_name")
                .setValue(response.data.ifscDetails.name);
              this.candidateForm
                .get("bank_branch")
                .setValue(response.data.ifscDetails.branch);
            } else {
              window.alert("Please,Enter Valid bank details!");
            }
          } else {
            window.alert('Somthing went wrong!');
          }
          // Hide loading
          this.hideLoading();
          this.isBankVerified = true;
          this.BankVerMsg = "Bank Account is Verified";
        },
        (error) => {
          console.log("error");
          console.log(error);

          // Hide loading
          this.hideLoading();
          window.alert("Error verifying bank details");
        }
      );
    // Hide loading
    this.hideLoading();
    this.isBankVerified = true;
    this.BankVerMsg = "Bank Account is Verified";
    this.candidateForm.get("bank_ac_status").setValue("verified");
  }
  // selectPanPhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "pan");
  //     } else {
  //       // Handle invalid file type error
  //       alert("Please select a JPG/PNG/PDF file for PAN photo.");
  //       event.target.value = ""; // Clear the file input
  //     }
  //   }
  // }

  // // Method to handle selection of Aadhar photo
  // selectAadharPhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "aadharPhoto");
  //     } else {
  //       // Handle invalid file type error
  //       alert("Please select a JPG/PNG/PDF file for Aadhar photo.");
  //       event.target.value = ""; // Clear the file input
  //     }
  //   }
  // }
  // selectBankChequePhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "bankcheque");
  //     } else {
  //       alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
  //       event.target.value = "";
  //     }
  //   }
  // }
  // selectProfilePhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "profilePhoto");
  //     } else {
  //       alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
  //       event.target.value = "";
  //     }
  //   }
  // }
  // selectSignPhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "SignPhoto");
  //     } else {
  //       alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
  //       event.target.value = "";
  //     }
  //   }
  // }
  // selectQuliDocPhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "QuliDocPhoto");
  //     } else {
  //       alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
  //       event.target.value = "";
  //     }
  //   }
  // }
  // selectEmpDocPhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "EmpDocPhoto");
  //     } else {
  //       alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
  //       event.target.value = "";
  //     }
  //   }
  // }
  // selectCovidOnePhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "covidOnePhoto");
  //     } else {
  //       alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
  //       event.target.value = "";
  //     }
  //   }
  // }
  // selectCovidTwoPhoto(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileType = file.type;
  //     if (
  //       fileType === "image/jpeg" ||
  //       fileType === "image/png" ||
  //       fileType === "application/pdf"
  //     ) {
  //       this.readFile(file, "covidTwoPhoto");
  //     } else {
  //       alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
  //       event.target.value = "";
  //     }
  //   }
  // }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  // Method to read file and set photo
  // readFile(file: File, type: string) {
  //   const reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     // Create an image element
  //     const img = new Image();
  //     img.src = e.target.result;

  //     // When the image is loaded
  //     img.onload = () => {
  //       // Create a canvas element
  //       const canvas = document.createElement('canvas');
  //       const ctx = canvas.getContext('2d');

  //       // Set the canvas dimensions to the image dimensions
  //       const MAX_WIDTH = 800; // Define maximum width
  //       const MAX_HEIGHT = 600; // Define maximum height
  //       let width = img.width;
  //       let height = img.height;

  //       if (width > MAX_WIDTH) {
  //         height *= MAX_WIDTH / width;
  //         width = MAX_WIDTH;
  //       }
  //       if (height > MAX_HEIGHT) {
  //         width *= MAX_HEIGHT / height;
  //         height = MAX_HEIGHT;
  //       }

  //       // Set canvas dimensions
  //       canvas.width = width;
  //       canvas.height = height;

  //       // Draw image on canvas with resized dimensions
  //       ctx.drawImage(img, 0, 0, width, height);

  //       // Get resized image as data URL
  //       const resizedDataUrl = canvas.toDataURL('image/jpeg');

  //       // Set the resized image data URL in the form control based on type

  //       if (type === "pan") {
  //         this.panPhoto = resizedDataUrl;
  //         this.candidateForm.controls["pan_photo"].setValue(resizedDataUrl);
  //       } else if (type === "bankcheque") {
  //         this.bankChequePhoto = resizedDataUrl;
  //         this.candidateForm.controls["bank_cheque_photo"].setValue(
  //           resizedDataUrl
  //         );
  //       } else if (type === "profilePhoto") {
  //         this.profilePhoto = resizedDataUrl;
  //         this.candidateForm.controls["profile_photo"].setValue(resizedDataUrl);
  //       } else if (type === "SignPhoto") {
  //         this.SignPhoto = resizedDataUrl;
  //         this.candidateForm.controls["release_signature"].setValue(
  //           resizedDataUrl
  //         );
  //       } else if (type === "EmpDocPhoto") {
  //         this.EmpDocPhoto = resizedDataUrl;
  //         this.candidateForm.controls["employment_documents_photo"].setValue(
  //           resizedDataUrl
  //         );
  //       } else if (type === "QuliDocPhoto") {
  //         this.QuliDocPhoto = resizedDataUrl;
  //         this.candidateForm.controls["qualification_documents_photo"].setValue(
  //           resizedDataUrl
  //         );
  //       } else if (type === "covidOnePhoto") {
  //         this.covidOnePhoto = resizedDataUrl;
  //         this.candidateForm.controls["covid_cert_one"].setValue(resizedDataUrl);
  //       } else if (type === "covidTwoPhoto") {
  //         this.covidTwoPhoto = resizedDataUrl;
  //         this.candidateForm.controls["covid_cert_two"].setValue(resizedDataUrl);
  //       } else {
  //         this.candidateForm.controls["aadhar_photo"].setValue(resizedDataUrl);
  //         this.aadharPhoto = resizedDataUrl;
  //       }
  //     };
  //   };

  //   // Read the file as data URL
  //   reader.readAsDataURL(file);
  // }

  async onSubmit() {
    console.log(this.candidateForm);
    if (this.candidateForm.valid) {
      this.loading = true;
      try {
        const formValues = this.candidateForm.value;
        // Remove blank values
        for (const [key, value] of Object.entries(formValues)) {
          if (value === "" || value === null) {
            delete formValues[key];
          }
        }
        // // List of image fields
        // const imageFields = [
        //   "profile_photo",
        //   "pan_photo",
        //   "aadhar_photo",
        //   "bank_cheque_photo",
        //   "qualification_documents_photo",
        //   "employment_documents_photo",
        //   "covid_cert_one",
        //   "covid_cert_two",
        // ];

        // // Upload each image one by one
        // for (const field of imageFields) {
        //   if (formValues[field]) {

        //     const formData = new FormData();
        //     formData.append("file", formValues[field]);
        //     formData.append("employeeCode", formValues.employeeCode);

        //     const result = await this.commonService
        //       .backendAPICall(`/upload/${field}`, formData)
        //       .toPromise();
        //     this.imageURLs[field] = result["url"];
        //   }
        // }

        // // Prepare data for saving employee
        // const employeeData = {
        //   ...formValues,
        //   ...this.imageURLs,
        // };

        const saveResult = await this.commonService
          .backendAPICall("/employee/save", formValues)
          .toPromise();
        if (saveResult["result"] == "success") {
          window.alert("Candidate registration request sent successfully");
          this.router.navigate(["/"]);
        } else {
          window.alert('Somthing went wrong!');
        }
      } catch (error) {
        window.alert('Somthing went wrong!');
        console.error("API Error:", error);
      } finally {
        this.loading = false;
      }
    } else {
      alert("Enter details properly");
      console.log(this.candidateForm.errors);
    }
  }

  async saveBgv() {
    const formValues = this.candidateForm.value;
    // Remove blank values
    for (const [key, value] of Object.entries(formValues)) {
      if (value === "" || value === null) {
        delete formValues[key];
      }
    }
    const saveResult = await this.commonService
      .backendAPICall("/employee/save", formValues)
      .toPromise();
    if (saveResult["result"] == "success") {
      this.candidateForm.get("id").setValue(saveResult["data"]["id"]);
      window.alert("data saved");
      console.log(this.candidateForm.value);
    } else {
      window.alert("somthing went wrong!");
    }
  }

  printBgv() {
    const printContent = document.getElementById("BGVForm")?.innerHTML;
    if (!printContent) {
      console.error("Element not found");
      return;
    }

    const windowPrint = window.open("", "", "width=800,height=600");
    if (windowPrint) {
      windowPrint.document.write(`
        <html>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
          <body>
            ${printContent}
          </body>
        </html>
      `);
      windowPrint.document.close();
      setTimeout(() => {
        windowPrint.print();
      }, 2000);
    }
  }
  printMedical() {
    const printContent = document.getElementById("medicalForm")?.innerHTML;
    if (!printContent) {
      console.error("Element not found");
      return;
    }

    const windowPrint = window.open("", "", "width=800,height=600");
    if (windowPrint) {
      windowPrint.document.write(`
        <html>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
          <body>
            ${printContent}
          </body>
        </html>
      `);
      windowPrint.document.close();
      setTimeout(() => {
        windowPrint.print();
      }, 2000);
    }
  }

  toggleNoPanCheckbox(event: any): void {
    this.hasNoPan = event.target.checked;
    if (this.hasNoPan) {
      this.candidateForm.get("pan_number").clearValidators();
    } else {
      this.candidateForm.get("pan_number").setValidators(Validators.required);
    }
    this.candidateForm.get("pan_number").updateValueAndValidity();
  }
  toggleNoBankCheckbox(event: any): void {
    this.hasNoBank = event.target.checked;
    if (this.hasNoBank) {
      this.candidateForm.get("bank_ac_number").clearValidators();
      this.candidateForm.get("bank_ifsc").clearValidators();
      this.candidateForm.get("bank_ac_name").clearValidators();
      this.candidateForm.get("bank_name").clearValidators();
    } else {
      this.candidateForm
        .get("bank_ac_number")
        .setValidators(Validators.required);
      this.candidateForm.get("bank_ifsc").setValidators(Validators.required);
      this.candidateForm.get("bank_ac_name").setValidators(Validators.required);
      this.candidateForm.get("bank_name").setValidators(Validators.required);
    }
    this.candidateForm.get("bank_ac_number").updateValueAndValidity();
    this.candidateForm.get("bank_ifsc").updateValueAndValidity();
    this.candidateForm.get("bank_ac_name").updateValueAndValidity();
    this.candidateForm.get("bank_name").updateValueAndValidity();
  }

  selectImage(event: any, field: string) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, field);
      } else {
        alert(
          "Please select a JPG/PNG/PDF file for the " +
            field.replace("_", " ") +
            "."
        );
        event.target.value = "";
      }
    }
  }

  readFile(file: File, field: string) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 600;
        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        const resizedDataUrl = canvas.toDataURL("image/jpeg");
        this.uploadToS3(file, resizedDataUrl, field);
      };
    };
    reader.readAsDataURL(file);
  }

  async uploadToS3(file: File, resizedDataUrl: string, field: string) {
    const formData = new FormData();
    formData.append("file", file);
    if (this.candidateForm.get("id").value) {
      formData.append("id", this.candidateForm.get("id").value);
    }
    this.imgLoading[field] = true;
    try {
      const response: any = await this.commonService
        .uploadImage(`/upload/${field}`, formData)
        .toPromise();
      const imageUrl = response.url;
      this.candidateForm.controls[field].setValue(imageUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload image");
    } finally {
      this.imgLoading[field] = false; // Set loading to false for this field
    }
  }

  async removeImage(field: string) {
    const imageUrl = this.candidateForm.controls[field].value;
    if (imageUrl) {
      this.imgLoading[field] = true;
      try {
        await this.commonService
          .backendAPICall("/remove-image", { url: imageUrl })
          .toPromise();
        this.candidateForm.controls[field].setValue(null);
      } catch (error) {
        console.error("Error removing file:", error);
        alert("Failed to remove image");
      } finally {
        this.imgLoading[field] = false; // Set loading to false for this field
      }
    }
  }

  replaceUnderscores(field: string): string {
    return field.replace(/_/g, " ");
  }

  getCandidateData(userId: string, sessionId: string): void {
    this.commonService
      .AuthAPICall("user", {
        user_id: userId,
        session_id: sessionId,
      })
      .subscribe(
        (data) => {
          this.candidateData = data["data"];
          this.setFormValues(this.candidateData);
        },
        (error) => {
          console.error("Error fetching candidate data:", error);
        }
      );
  }
  setFormValues(candidateData) {
    console.log(candidateData);

    this.candidateForm.reset();
    this.candidateForm.get("first_name").setValue(candidateData.table.Name);
    this.candidateForm
      .get("nick_name")
      .setValue(candidateData.export["First child name"]);
    this.candidateForm.get("gender").setValue("male");
    this.candidateForm
      .get("father_husband_name")
      .setValue(candidateData.export["father's name"]);
    this.candidateForm.get("relation").setValue("Father");
    this.candidateForm
      .get("mobile")
      .setValue(candidateData.table["Phone Number"].slice(2));
    this.candidateForm.get("mailOtp").setValue("");
    this.candidateForm.get("email").setValue("");
    this.candidateForm.get("pan_number").setValue("");
    this.candidateForm
      .get("aadhar_number")
      .setValue(candidateData.export["spouse aadhar number"]);
    this.candidateForm
      .get("date_of_birth")
      .setValue(candidateData.export["date Of Birth(Manually)"]);
    this.candidateForm.get("aadharOtp").setValue("");
    this.candidateForm.get("bank_ac_number").setValue("");
    this.candidateForm.get("bank_ac_name").setValue("");
    this.candidateForm.get("bank_branch").setValue("");
    this.candidateForm.get("bank_name").setValue("");
    this.candidateForm.get("bank_ifsc").setValue("");
    this.candidateForm.get("pincode").setValue("");
    this.candidateForm.get("date_of_joining").setValue("");
    this.candidateForm.get("employee_code").setValue("");
    this.candidateForm.get("designation").setValue("");
    this.candidateForm.get("department").setValue("");
    this.candidateForm.get("department_location").setValue("");
    this.candidateForm.get("marital_status").setValue("single");
    this.candidateForm.get("nationality").setValue("");
    this.candidateForm.get("former_maiden_name").setValue("");
    this.candidateForm.get("date_of_name_change").setValue("");
    this.candidateForm.get("home_phone").setValue("");
    this.candidateForm.get("personal_email").setValue("");
    this.candidateForm.get("mobile_phone").setValue("");
    this.candidateForm.get("office_phone").setValue("");
    this.candidateForm.get("current_address").setValue("");
    this.candidateForm.get("permanent_address").setValue("");
    this.candidateForm.get("current_landmark").setValue("");
    this.candidateForm.get("permanent_landmark").setValue("");
    this.candidateForm.get("current_pin").setValue("");
    this.candidateForm.get("permanent_pin").setValue("");
    this.candidateForm.get("residing_from_current").setValue("");
    this.candidateForm.get("residing_to_current").setValue("");
    this.candidateForm.get("residing_from_permanent").setValue("");
    this.candidateForm.get("residing_to_permanent").setValue("");
    this.candidateForm.get("current_contact").setValue("");
    this.candidateForm.get("permanent_contact").setValue("");
    this.candidateForm
      .get("year_of_passing")
      .setValue(candidateData.export["Year of passing"]);
    this.candidateForm.get("attended_to").setValue("");
    this.candidateForm.get("attended_from").setValue("");
    this.candidateForm.get("id_roll_seat_no").setValue("");
    this.candidateForm.get("mode").setValue("Fulltime");
    this.candidateForm
      .get("document_submission_status")
      .setValue("All Year Mark Sheets");
    this.candidateForm.get("engaged_in_other_business").setValue(false);
    this.candidateForm
      .get("dismissed_from_previous_employment")
      .setValue(false);
    this.candidateForm.get("convicted_of_criminal_offence").setValue(false);
    this.candidateForm.get("civil_judgments_against_you").setValue(false);
    this.candidateForm.get("health_problems_details").setValue(false);
    this.candidateForm.get("significant_illness_details").setValue(false);
    this.candidateForm
      .get("health_problems_affect_performance")
      .setValue(false);
    this.candidateForm.get("special_aids_required").setValue(false);
    this.candidateForm.get("release_date").setValue("");
    this.candidateForm.get("release_name").setValue("");
    this.candidateForm.get("profile_photo").setValue("");
    this.candidateForm.get("pan_photo").setValue("");
    this.candidateForm.get("bank_cheque_photo").setValue("");
    this.candidateForm.get("aadhar_photo").setValue("");
    this.candidateForm.get("qualification_documents_photo").setValue("");
    this.candidateForm.get("employment_documents_photo").setValue("");
    this.candidateForm.get("release_signature").setValue("");
  }
  goBack(): void {
    this.router.navigate(["/admin/dashboard"]);
  }
  toggleFresherStatus(): void {
    this.isFresher = !this.isFresher;
  }
}
