import { Component } from "@angular/core";

@Component({
  selector: "app-not-found",
  template: `
    <div class="container my-5">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center">
          <h1 class="display-1 font-weight-bold">404</h1>
          <h2 class="mb-4">Page Not Found</h2>
          <p class="lead mb-5">The page you are looking for does not exist.</p>
          <a routerLink="/candidate-form" class="btn btn-primary btn-lg">Go to Home</a>
        </div>
      </div>
    </div>
  `,
})
export class NotFoundComponent {}
